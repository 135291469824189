import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes
} from '@angular/router';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { Error } from '@app/core/errors/errors.model';
import { AppComponent } from './app.component';
import { ErrorsComponent } from './core/errors/errors-component/errors.component';
import { FeatureFlagGuard } from './core/feature-flag/feature-flag.guard';
import { DIVERSIFICATION_DEFAULT_ROUTE } from './diversification/constants/routes';
import { NEW_DIVERSIFICATION_DEFAULT_ROUTE } from './new-diversification/constants/routes';
import { NO_PLANS_ROUTE } from './no-plans/no-plans.constants';
import { OPEN_INSURANCE_ROUTE } from './open-insurance/shared/constants/routes';
import { PlanTradUnsignedGuard } from './plan-blocked.guard';
import { PlatformStore } from './platform-store.resolver';
import { ProfileUpdatedGuard } from './profile-updated.guard';
import { UnsignedContractGuard } from './unsigned-contract.guard';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    pathMatch: 'full',
    resolve: { resolve: PlatformStore }
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./user/user.module').then(
        (m) => m.UserModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./lgpd/lgpd.module').then(
        (m) => m.LgpdModule
      ),
    canActivate: [AuthGuard, ProfileUpdatedGuard]
  },
  {
    path: 'validation',
    loadChildren: () =>
      import('./validation/validation.module').then(
        (m) => m.ValidationModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then(
        (m) => m.HomeModule
      ),
    canActivate: [
      AuthGuard,
      UnsignedContractGuard,
      ProfileUpdatedGuard,
      PlanTradUnsignedGuard
    ]
  },
  {
    path: 'plan-manager',
    loadChildren: () =>
      import('./plan-manager/plan-manager.module').then(
        (m) => m.PlanManagerModule
      ),
    canActivate: [
      AuthGuard,
      UnsignedContractGuard,
      ProfileUpdatedGuard,
      PlanTradUnsignedGuard
    ]
  },
  {
    path: 'hiring',
    loadChildren: () =>
      import('./hiring/hiring.module').then(
        (m) => m.HiringModule
      ),
    canActivate: [
      AuthGuard,
      UnsignedContractGuard,
      ProfileUpdatedGuard
    ]
  },
  {
    path: 'reallocation',
    loadChildren: () =>
      import('./reallocation/reallocation.module').then(
        (m) => m.ReallocationModule
      ),
    canActivate: [
      AuthGuard,
      UnsignedContractGuard,
      ProfileUpdatedGuard,
      PlanTradUnsignedGuard
    ]
  },
  {
    path: 'diversification',
    loadChildren: () =>
      import(
        './funds-diversification/diversification.module'
      ).then((m) => m.DiversificationModule),
    canActivate: [
      AuthGuard,
      ProfileUpdatedGuard,
      PlanTradUnsignedGuard
    ],
    canLoad: [FeatureFlagGuard],
    data: { flag: 'diversification' }
  },
  {
    path: DIVERSIFICATION_DEFAULT_ROUTE,
    loadChildren: () =>
      import(
        '@diversification/diversification.module'
      ).then((m) => m.DiversificationModule),
    canActivate: [
      AuthGuard,
      ProfileUpdatedGuard,
      PlanTradUnsignedGuard
    ]
  },
  {
    path: NEW_DIVERSIFICATION_DEFAULT_ROUTE,
    loadChildren: () =>
      import(
        './new-diversification/new-diversification.module'
      ).then((m) => m.NewDiversificationModule),
    canActivate: [
      AuthGuard,
      ProfileUpdatedGuard,
      PlanTradUnsignedGuard
    ]
  },
  {
    path: 'plan',
    loadChildren: () =>
      import('./plan/plan.module').then(
        (m) => m.PlanModule
      ),
    canActivate: [
      AuthGuard,
      UnsignedContractGuard,
      ProfileUpdatedGuard
    ]
  },
  {
    path: 'withdraw',
    loadChildren: () =>
      import('./withdraw/withdraw.module').then(
        (m) => m.WithdrawModule
      ),
    canActivate: [
      AuthGuard,
      UnsignedContractGuard,
      ProfileUpdatedGuard,
      PlanTradUnsignedGuard
    ]
  },
  {
    path: 'checkup',
    loadChildren: () =>
      import('./checkup/checkup.module').then(
        (m) => m.CheckupModule
      ),
    canActivate: [
      AuthGuard,
      UnsignedContractGuard,
      ProfileUpdatedGuard,
      PlanTradUnsignedGuard
    ]
  },
  {
    path: 'decumulation',
    loadChildren: () =>
      import('./decumulation/decumulation.module').then(
        (m) => m.DecumulationModule
      ),
    canActivate: [
      AuthGuard,
      UnsignedContractGuard,
      ProfileUpdatedGuard,
      PlanTradUnsignedGuard
    ]
  },
  {
    path: 'upselling',
    loadChildren: () =>
      import('./upselling/upselling.module').then(
        (m) => m.UpsellingModule
      ),
    canActivate: [
      AuthGuard,
      UnsignedContractGuard,
      ProfileUpdatedGuard,
      PlanTradUnsignedGuard
    ]
  },
  {
    path: 'invoices',
    loadChildren: () =>
      import('./boletos/boletos.module').then(
        (m) => m.BoletosModule
      ),
    canActivate: [
      AuthGuard,
      UnsignedContractGuard,
      ProfileUpdatedGuard,
      PlanTradUnsignedGuard
    ]
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./support/support.module').then(
        (m) => m.SupportModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'certificate',
    loadChildren: () =>
      import('./certificate/certificate.module').then(
        (m) => m.CertificateModule
      ),
    canActivate: [AuthGuard, ProfileUpdatedGuard]
  },
  {
    path: OPEN_INSURANCE_ROUTE,
    loadChildren: () =>
      import('./open-insurance/open-insurance.module').then(
        (m) => m.OpenInsuranceModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: NO_PLANS_ROUTE,
    loadChildren: () =>
      import('./no-plans/no-plans.module').then(
        (m) => m.NoPlansModule
      ),
    canActivate: [AuthGuard, ProfileUpdatedGuard]
  },
  { path: 'error', component: ErrorsComponent },
  {
    path: '**',
    component: ErrorsComponent,
    data: { error: 404 } as Error
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
